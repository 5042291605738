define('m0601/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;
      // var skalierungsfaktor = x3d.get('skalierungsfaktor')+0.6;
      var skalierungsfaktor = 1;

      var item = x3d.get('bauteile').findBy('id', name);

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var abstandVonDerMasslinie = 0.05;
      var korrektur = 0.05;

      switch (typ) {
        case 'sparrenabstand':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = -item.get('grundmasse').objectAt(0).get('laengeF1') / 20 - 0.3 - (0.6 * skalierungsfaktor + 0.14 / skalierungsfaktor);
            y = 0;
            z = 0;
          } else {

            console.log('----- sparrenabstand -----');

            x = -item.get('grundmasse').objectAt(0).get('laengeF1') / 20;
            y = -0.5 - 0.15 * skalierungsfaktor;
            z = 0;
          }
          break;
        case 'breite':
          if (name === 'lager2') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
              y = 0;
              z = +hoeheMasskette - abstandVonDerMasslinie + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            } else {
              x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
              y = -hoeheMasskette + abstandVonDerMasslinie;
              z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20 + bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('z') / 20;
            }
          } else if (name === 'lager1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
              y = 0;
              z = +hoeheMasskette - abstandVonDerMasslinie + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            } else {
              x = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20;
              y = -hoeheMasskette + abstandVonDerMasslinie;
              z = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion') / 20 + bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('z') / 20;
            }
          } else if (name === 'unterkonstruktion1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -hoeheMasskette - abstandVonDerMasslinie - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
              y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);
              z = 0;
            } else {
              x = -(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20) - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
              y = -hoeheMasskette + abstandVonDerMasslinie - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
              z = 0;
            }
          } else if (name === 'beton') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0;
              y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) - bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
              z = abstandVomBauteil + 2 * hoeheMasskette - abstandVonDerMasslinie + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20;
            } else {
              x = 0;
              y = -abstandVomBauteil - 2 * hoeheMasskette + abstandVonDerMasslinie - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
              z = bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20;
            }
          }

          break;
        case 'tiefe':
          if (name === 'lager1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 - abstandVomBauteil - 2 * hoeheMasskette - abstandVonDerMasslinie;;
              y = 0;
              z = 0;
            } else {
              x = -bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              y = -abstandVomBauteil - 2 * hoeheMasskette + abstandVonDerMasslinie;
              z = 0;
            }
          }
          break;

        case 'hoehe':
          if (name === 'unterkonstruktion2') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 + bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;
              y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
              z = hoeheMasskette + abstandVonDerMasslinie + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20;
            } else {
              x = hoeheMasskette + abstandVonDerMasslinie + bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 + bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;
              y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
              z = bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('z') / 20;
            }
          } else if (name === 'beton') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 + bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;
              y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
              z = hoeheMasskette + abstandVonDerMasslinie + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            } else {
              x = hoeheMasskette + abstandVonDerMasslinie + bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 + bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).get('x') / 20;
              y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
              z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            }
          } else if (name === 'schalung') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20) - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
              y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
              z = -hoeheMasskette - abstandVonDerMasslinie - bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            } else {
              x = -hoeheMasskette - abstandVonDerMasslinie - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 20;
              y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
              z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            }
          }
          break;
        case 'randabstandlinks':
          x = -(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20) + bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1RandRechts') / 20;
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            y = 0;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + 2 * abstandVomBauteil + 3 * hoeheMasskette - abstandVonDerMasslinie;
          } else {
            y = -(2 * abstandVomBauteil) - 3 * hoeheMasskette + abstandVonDerMasslinie;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
          }
          break;
        case 'randabstandrechts':
          x = bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 20 - bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2RandLinks') / 20;;
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            y = 0;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + 2 * abstandVomBauteil + 3 * hoeheMasskette - abstandVonDerMasslinie;
          } else {
            y = -(2 * abstandVomBauteil) - 3 * hoeheMasskette + abstandVonDerMasslinie;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
          }
          break;

      }

      if (name.substring(0, 8) === 'schraube') {

        var xKopfPos = this.getSchraubeKopfPos(name, 'x');
        var zKopfPos = this.getSchraubeKopfPos(name, 'z');

        if (this.get('typ') === 'xPos') {
          if (x3d.get('istHt')) {
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('z') / 20;
          } else {
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y') / 20;
          }

          if (this.austrittspunktRechts) {
            cords = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 1 + 0.5 * skalierungsfaktor - 0.2 / skalierungsfaktor - 0.25 + ' ' + -xKopfPos / 2 + ' ' + (-z - 0.3);
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = xKopfPos / 2 + ' ' + (0.5 * skalierungsfaktor - 0.3 / skalierungsfaktor - 1) + ' ' + (-z - 0.3);
            } else {
              cords = xKopfPos / 2 + ' 0 ' + (-z - (0.5 * skalierungsfaktor - 0.2 / skalierungsfaktor));
            }
          }
        }

        if (this.get('typ') === 'zPos') {

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') && !x3d.get('istHt')) {
            x = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 0.5;
          } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 0.3;
          } else {
            x = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10;
          }

          var delta = 0;
          var zPos = 0;
          var bezugspunkt = 0;

          switch (this.get('typ')) {
            case 'randAbstand1':
              bezugspunkt = -x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt + x3d.get('randAbstand1') / 20;
              delta = 0.6;
              break;
            case 'randAbstand2':
              bezugspunkt = x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt - x3d.get('randAbstand2') / 20;
              break;
            case 'schraubenAbstand1':
              bezugspunkt = -x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt + x3d.get('schraubenAbstand1') / 20 + x3d.get('randAbstand1') / 10;
              delta = 0.6;
              break;
            case 'schraubenAbstand2':
              bezugspunkt = x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt - x3d.get('schraubenAbstand2') / 20 - x3d.get('randAbstand2') / 10;
              break;
          }

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            cords = x + ' ' + (0.5 * skalierungsfaktor - 0.3 / skalierungsfaktor + delta - 0.35) + ' ' + zPos;
          } else {
            cords = x + delta + (0.5 * skalierungsfaktor - 0.3 / skalierungsfaktor) + ' ' + y + ' ' + zPos;
          }
        }
      }

      if (name === 'lastenPfeil') {
        y = -0.4 - hoeheMasskette + this.positionDurchSkalierung(0);
      }

      cords = x + ' ' + y + ' ' + z;
      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.viewpointHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');
      var values;
      var x = 0,
          y = 0,
          z = 0,
          omega = 0;
      var w90Grad = 90 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;

      var item = x3d.get('bauteile').findBy('id', name);

      switch (typ) {
        case 'breite':
          if (name === 'lager1' || name === 'lager2') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 0;
              z = 0;
              omega = -w90Grad;
            } else {
              x = 0;
              y = 0;
              z = 0;
              omega = 0;
            }
          } else if (name === 'unterkonstruktion1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = -1;
              z = -1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          } else if (name === 'beton') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 0;
              z = 0;
              omega = -w90Grad;
            } else {
              x = 0;
              y = 0;
              z = 0;
              omega = 0;
            }
          }
          break;

        case 'tiefe':
          if (name === 'lager1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = -1;
              z = -1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          }
          break;

        case 'hoehe':
          if (name === 'beton' || name === 'unterkonstruktion2') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -1;
              y = 1;
              z = 1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 0;
              z = 1;
              omega = -w90Grad;
            }
          } else if (name === 'schalung') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 1;
              z = -1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 0;
              z = 1;
              omega = w90Grad;
            }
          }
          break;
        case 'randabstandlinks':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
        case 'randabstandrechts':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
      }

      values = x + ' ' + y + ' ' + z + ' ' + omega;

      if (name.substring(0, 8) === 'schraube') {

        if (this.get('typ') === 'xPos') {
          if (this.austrittspunktRechts) {
            values = '0 0 1 -1.5707';
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '0 0 0 0';
            } else {
              values = '1 0 0 -1.5707';
            }
          }
        }

        if (this.get('typ') === 'zPos') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 1 0 -1.5707';
          } else {
            values = '1 1 1 4.2';
          }
        }
      }

      if (name === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.viewpointHelper'),

    wert: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = ' ';

      var item = bauteile.findBy('id', name);

      // console.log('name: '+name+ ' ::: typ: '+typ);

      switch (typ) {
        case 'sparrenabstand':
          value = Number(item.get('grundmasse').objectAt(0).get('sparrenabstand')).toFixed(1);
          break;
        case 'breite':
          if (name === 'lager1' && x3d.get('auflager1breiteEingetragen') || name === 'lager2' && x3d.get('auflager2breiteEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('x')).toFixed(1);
          } else if (name === 'unterkonstruktion1' && x3d.get('breiteEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          } else if (name === 'beton' && x3d.get('laengeF1Eingetragen')) {
            value = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1')).toFixed(1);
          }
          break;
        case 'hoehe':
          if (name === 'beton' && x3d.get('bDickeEingetragen') || name === 'unterkonstruktion2' && x3d.get('hoeheEingetragen') || name === 'schalung') {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          break;
        case 'tiefe':
          if (name === 'lager1' && x3d.get('bAbstandEingetragen')) {
            value = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')).toFixed(1);
          }
          break;
        case 'randabstandlinks':
          if (x3d.get('f2aEingetragen')) {
            value = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1RandRechts')).toFixed(1);
          }
          break;
        case 'randabstandrechts':
          if (x3d.get('f1aEingetragen')) {
            value = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2RandLinks')).toFixed(1);
          }
          break;
      }

      if (name === 'lastenPfeil') {
        value = x3d.get('bemessungslast');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('hoeheAktiv') === true && name === 'unterkonstruktion2') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('breiteAktiv') === true && name === 'unterkonstruktion1') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('auflager1breiteAktiv') === true && name === 'lager1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('bAbstandAktiv') === true && name === 'lager1' && typ === 'tiefe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('auflager2breiteAktiv') === true && name === 'lager2') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('bAbstandAktiv') === true && name === 'lager2') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('f1aAktiv') === true && name === 'schalung' && typ === 'randabstandrechts') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('f2aAktiv') === true && name === 'schalung' && typ === 'randabstandlinks') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('laengeF1Aktiv') === true && name === 'beton' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('schalungAktiv') === true && name === 'schalung' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('bDickeAktiv') === true && name === 'beton' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.breiteAktiv', 'model.firstObject.hoeheAktiv', 'model.firstObject.auflager1breiteAktiv', 'model.firstObject.auflager2breiteAktiv', 'model.firstObject.bAbstandAktiv', 'model.firstObject.f1aAktiv', 'model.firstObject.f2aAktiv', 'model.firstObject.laengeF1Aktiv', 'model.firstObject.schalungAktiv', 'model.firstObject.bDickeAktiv'),

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }).property('model.firstObject.skalierungsfaktor', 'model.firstObject.transformHelper'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {
      var value = 0;

      var x3d = this.get('model').objectAt(0);

      var item = x3d.get('bauteile').findBy('id', name);

      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

      // var xKopfPos = -(Math.cos(beta)*yVerschiebung/Math.tan(alpha) - xVerschiebung);

      var xKopfPos;

      if (xVerschiebung > 0) {

        var vwinkel = x3d.get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);
        if (Number(xKopfPos) > Number(x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    },

    positionDurchSkalierung: function positionDurchSkalierung(richtung) {
      var x3d = this.get('model').objectAt(0);
      var skalierungsfaktor = x3d.get('skalierungsfaktor');
      // var value = ((0.6*skalierungsfaktor) + richtung*(0.14/skalierungsfaktor));
      // var value = (0.2*skalierungsfaktor);
      var value = 0.3 * skalierungsfaktor - 0.3 * skalierungsfaktor * 0.66;
      return 0;
    }

  });

  exports['default'] = x3dText;

});